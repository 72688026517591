import auth from "@/middleware/auth";
import admin from "@/middleware/admin";

export const undesiredCancellationRoutes = [
  {
    path: "/directories/undesired-cancellation",
    name: "UndesiredCancellationList",
    meta: { middleware: [auth, admin] },
    component: () =>
      import(
        /* webpackChunkName: "undesiredCancellation" */ "./components/List"
      ),
  },
];
