import auth from "@/middleware/auth";
import admin from "@/middleware/admin";

export const takenRoutes = [
  {
    path: "/directories/taken",
    name: "TakenList",
    meta: { middleware: [auth, admin] },
    component: () =>
      import(/* webpackChunkName: "taken" */ "./components/List"),
  },
];
