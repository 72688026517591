import auth from "@/middleware/auth";
import admin from "@/middleware/admin";

export const undesiredPssRoutes = [
  {
    path: "/directories/undesired-pss",
    name: "UndesiredPssList",
    meta: { middleware: [auth, admin] },
    component: () =>
      import(/* webpackChunkName: "undesiredPss" */ "./components/List"),
  },
];
