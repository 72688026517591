import auth from "@/middleware/auth";
import admin from "@/middleware/admin";

export const clientRoutes = [
  {
    path: "/directories/client",
    name: "ClientList",
    meta: { middleware: [auth, admin] },
    component: () =>
      import(/* webpackChunkName: "client" */ "./components/List"),
  },
];
