import { createStore } from "vuex";
import * as auth from "@/store/modules/Auth";
import * as user from "@/store/modules/User";
import * as role from "@/store/modules/Role";
import * as message from "@/store/modules/Message";
import * as directory from "@/store/modules/Directory";

import adverseReaction from "../modules/adverseReaction/store";
import users from "../modules/users/store";
import messageType from "../modules/directories/messageType/store";
import senderType from "../modules/directories/senderType/store";
import studyType from "../modules/directories/studyType/store";
import studyNumber from "../modules/directories/studyNumber/store";
import studyName from "../modules/directories/studyName/store";
import patient from "../modules/directories/patient/store";
import treatment from "../modules/directories/treatment/store";
import agegroup from "../modules/directories/agegroup/store";
import allergy from "../modules/directories/allergy/store";
import client from "../modules/directories/client/store";
import taken from "../modules/directories/taken/store";
import repeat from "../modules/directories/repeat/store";
import unit from "../modules/directories/unit/store";
import gender from "../modules/directories/gender/store";
import country from "../modules/directories/country/store";
import region from "../modules/directories/region/store";
import medicineRole from "../modules/directories/medicineRole/store";
import medicineMedicine from "../modules/directories/medicineMedicine/store";
import medicineTn from "../modules/directories/medicineTn/store";
import medicineMnn from "../modules/directories/medicineMnn/store";
import medicineManufacturer from "../modules/directories/medicineManufacturer/store";
import medicineSingledoseUnit from "../modules/directories/medicineSingledoseUnit/store";
import medicineReceptionUnit from "../modules/directories/medicineReceptionUnit/store";
import medicineDate from "../modules/directories/medicineDate/store";
import medicineDurationUnit from "../modules/directories/medicineDurationUnit/store";
import medicineDosageForm from "../modules/directories/medicineDosageForm/store";
import medicineLeading from "../modules/directories/medicineLeading/store";
import medicineIndication from "../modules/directories/medicineIndication/store";
import medicineIndicationOther from "../modules/directories/medicineIndicationOther/store";
import medicineActions from "../modules/directories/medicineActions/store";
import undesiredDescription from "../modules/directories/undesiredDescription/store";
import undesiredSeverity from "../modules/directories/undesiredSeverity/store";
import undesiredForesight from "../modules/directories/undesiredForesight/store";
import undesiredPss from "../modules/directories/undesiredPss/store";
import undesiredCancellation from "../modules/directories/undesiredCancellation/store";
import undesiredExodus from "../modules/directories/undesiredExodus/store";
import undesiredLang from "../modules/directories/undesiredLang/store";
import addressType from "../modules/directories/addressType/store";

//const debug = process.env.NODE_ENV !== 'production';
const debug = true;

export default new createStore({
  strict: debug,

  modules: {
    auth,
    user,
    role,
    message,
    directory,

    adverseReaction,
    users,
    messageType,
    senderType,
    studyType,
    studyNumber,
    studyName,
    patient,
    treatment,
    agegroup,
    allergy,
    client,
    taken,
    repeat,
    unit,
    gender,
    country,
    region,
    medicineRole,
    medicineMedicine,
    medicineTn,
    medicineMnn,
    medicineManufacturer,
    medicineSingledoseUnit,
    medicineReceptionUnit,
    medicineDate,
    medicineDurationUnit,
    medicineDosageForm,
    medicineLeading,
    medicineIndication,
    medicineIndicationOther,
    medicineActions,
    undesiredDescription,
    undesiredSeverity,
    undesiredForesight,
    undesiredPss,
    undesiredCancellation,
    undesiredExodus,
    undesiredLang,
    addressType,
  },
});
