import auth from "@/middleware/auth";
import admin from "@/middleware/admin";
import { usersRoutes } from "../modules/users/routes";
import { messageTypeRoutes } from "../modules/directories/messageType/routes";
import { senderTypeRoutes } from "../modules/directories/senderType/routes";
import { studyTypeRoutes } from "../modules/directories/studyType/routes";
import { studyNumberRoutes } from "../modules/directories/studyNumber/routes";
import { studyNameRoutes } from "../modules/directories/studyName/routes";
import { patientRoutes } from "../modules/directories/patient/routes";
import { treatmentRoutes } from "../modules/directories/treatment/routes";
import { allergyRoutes } from "../modules/directories/allergy/routes";
import { clientRoutes } from "../modules/directories/client/routes";
import { takenRoutes } from "../modules/directories/taken/routes";
import { repeatRoutes } from "../modules/directories/repeat/routes";
import { agegroupRoutes } from "../modules/directories/agegroup/routes";
import { unitRoutes } from "../modules/directories/unit/routes";
import { genderRoutes } from "../modules/directories/gender/routes";
import { countryRoutes } from "../modules/directories/country/routes";
import { regionRoutes } from "../modules/directories/region/routes";
import { medicineRoleRoutes } from "../modules/directories/medicineRole/routes";
import { medicineMedicineRoutes } from "../modules/directories/medicineMedicine/routes";
import { medicineTnRoutes } from "../modules/directories/medicineTn/routes";
import { medicineMnnRoutes } from "../modules/directories/medicineMnn/routes";
import { medicineManufacturerRoutes } from "../modules/directories/medicineManufacturer/routes";
import { medicineSingledoseUnitRoutes } from "../modules/directories/medicineSingledoseUnit/routes";
import { medicineReceptionUnitRoutes } from "../modules/directories/medicineReceptionUnit/routes";
import { medicineDateRoutes } from "../modules/directories/medicineDate/routes";
import { medicineDurationUnitRoutes } from "../modules/directories/medicineDurationUnit/routes";
import { medicineDosageFormRoutes } from "../modules/directories/medicineDosageForm/routes";
import { medicineLeadingRoutes } from "../modules/directories/medicineLeading/routes";
import { medicineIndicationRoutes } from "../modules/directories/medicineIndication/routes";
import { medicineIndicationOtherRoutes } from "../modules/directories/medicineIndicationOther/routes";
import { medicineActionsRoutes } from "../modules/directories/medicineActions/routes";
import { undesiredDescriptionRoutes } from "../modules/directories/undesiredDescription/routes";
import { undesiredSeverityRoutes } from "../modules/directories/undesiredSeverity/routes";
import { undesiredForesightRoutes } from "../modules/directories/undesiredForesight/routes";
import { undesiredPssRoutes } from "../modules/directories/undesiredPss/routes";
import { undesiredCancellationRoutes } from "../modules/directories/undesiredCancellation/routes";
import { undesiredExodusRoutes } from "../modules/directories/undesiredExodus/routes";
import { undesiredLangRoutes } from "../modules/directories/undesiredLang/routes";
import { addressTypeRoutes } from "../modules/directories/addressType/routes";

export const adminRoutes = [
  ...usersRoutes,
  ...messageTypeRoutes,
  ...senderTypeRoutes,
  ...studyTypeRoutes,
  ...studyNumberRoutes,
  ...studyNameRoutes,
  ...patientRoutes,
  ...treatmentRoutes,
  ...allergyRoutes,
  ...clientRoutes,
  ...takenRoutes,
  ...repeatRoutes,
  ...agegroupRoutes,
  ...unitRoutes,
  ...genderRoutes,
  ...countryRoutes,
  ...regionRoutes,
  ...medicineRoleRoutes,
  ...medicineMedicineRoutes,
  ...medicineTnRoutes,
  ...medicineMnnRoutes,
  ...medicineManufacturerRoutes,
  ...medicineSingledoseUnitRoutes,
  ...medicineReceptionUnitRoutes,
  ...medicineDateRoutes,
  ...medicineDurationUnitRoutes,
  ...medicineDosageFormRoutes,
  ...medicineLeadingRoutes,
  ...medicineIndicationRoutes,
  ...medicineIndicationOtherRoutes,
  ...medicineActionsRoutes,
  ...undesiredDescriptionRoutes,
  ...undesiredSeverityRoutes,
  ...undesiredForesightRoutes,
  ...undesiredPssRoutes,
  ...undesiredCancellationRoutes,
  ...undesiredExodusRoutes,
  ...undesiredLangRoutes,
  ...addressTypeRoutes,
  {
    path: "/_users",
    name: "_users",
    meta: { middleware: [auth, admin] },
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/Admin/Users"),
  },
  {
    path: "/_users/:id",
    name: "_users.detail",
    meta: { middleware: [auth, admin] },
    component: () =>
      import(
        /* webpackChunkName: "users.detail" */ "../views/Admin/Users/Detail"
      ),
  },
];
